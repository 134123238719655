'use strict';

const { post } = require("jquery");

let _$ = jQuery;

let $loader = _$(".form-loading");
let $result = _$(".form-result");
let $result_content = _$(".form-result-inner");

const showResult = (msg) => {
  $result.css("opacity", 0.01);
  $result_content.html( msg );
  $result.removeClass("hide").fadeTo(1200, 1);
};

const closeResult = () => {
  $result.addClass("hide");
  $result_content.html("");
};

const ajaxError = (jqXHR, textStatus, errorThrown) => {
  console.log(jqXHR);
  console.log(textStatus);
  console.log(errorThrown);
};

jconfirm.defaults = {
  draggable: false,
  theme: 'modern',
  title: '',
  useBootstrap: false,
  boxWidth: '500px'
};

let validations = [];
_$(".js-valid").each(function() {
  let $this = _$(this);
  let $error = $this.next(".js-error");
  $error.before("<br>");
  let label = _$("label[for=" + $this.attr('id') + "]");
  label = label.html().replace(/\s\<.+\>/i, "");
  $this.data("valid", false);
  let valid = function() {
    let val = $this.val();
    // 必須入力チェック
    if( $this.attr("required") == "required" && val == "" ) {
      $this.data("valid", false);
      $error.text(label + "は必須です").removeClass("hide");
      return false;
    }
    if( $this.data("number") == true && /^[0-9]+$/.test(val) == false ) {
      $this.data("valid", false);
      $error.text(label + "は数字のみで入力してください").removeClass("hide");
      return false;
    }
    // セレクトチェック
    if( $this.data("multi_select") == true || $this.data("select") == true ) {
      if( $this.data("multi_select") == true ) val = val.filter(n => n);
      if( val.length == 0 ) {
        $this.data("valid", false);
        $error.removeClass("hide");
        return false;
      }
    }
    // パスワードチェック（将来の機能）
    let type = $this.attr("type");
    switch( type ) {
      case "email":
        if( /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(val) == false ) {
          $error.text(label + "は正しいメールアドレスを入力してください").removeClass("hide");
          return false;
        }
        break;
      case "tel":
        if( /^([0-9]{2,4}-?[0-9]{3,4}-?[0-9]{3,4})$/.test(val) == false ) {
          $error.text(label + "は正しい電話番号を入力してください").removeClass("hide");
          return false;
        }
        break;
      case "url":
        if( /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(val) == false ) {
          $error.text(label + "は正しいURLを入力してください").removeClass("hide");
          return false;
        }
        break;
      default:
        break;
    }
    $this.data("valid", true);
    $error.addClass("hide");
    return true;
  };
  validations.push(valid);
  $this.on("focusout", valid);
});

const checkErrors = (validations) => {
  let error_num = 0;
  for(let i=0; i<validations.length; i++) {
    let valid = validations[i];
    if( typeof valid == "function" ) {
      let result = valid();
      if( !result ) error_num++;
    }
  }
  return error_num;
};

const getFormData = ($form) => {
  let post_data = $form.serializeArray();
  let post_data_json = {};
  post_data.map( arg => {
    if( /\[\]/.test( arg.name ) ) {
      if( arg.value == "" ) return;
      let key = arg.name.replace( "[]", "" );
      if( typeof post_data_json[key] == "undefined" ) post_data_json[key] = arg.value;
      else post_data_json[key] += "," + arg.value;
    } else {
      post_data_json[arg.name] = arg.value;
    }
  });
  return post_data_json;
};


// ------------------------------------------------------------------------
// input[type=file] のプレビュー処理
_$(".js-image-selector").on("change", function(e) {
  let $this = _$(this);
  let upfile = e.target.files[0];
  if( upfile == 'undefined' ) return;
  let reader = new FileReader();
  let $preview = $this.prev(".preview");
  let $remove = $this.next(".js-image-remover");
  let $target = _$("#" + $this.data("target"));
  let $title = _$("#" + $this.data("title"));
  
  $loader.removeClass("hide");
  reader.onload = function(ev) {
    $preview.html('<img src="' + ev.target.result + '" alt="">');
  }
  let formData = new FormData();
  formData.append('action', 'upload-attachment');
  formData.append('async-upload', upfile);
  formData.append('name', upfile.name);
  formData.append('_wpnonce', mypage_ajax.image_nonce);

  // 画像アップロード処理（もし操作を途中でやめた場合、ブラウザ離脱時に削除処理を入れた方が良い）
  _$.ajax({
    url: mypage_ajax.image_url,
    data: formData,
    processData: false,
    contentType: false,
    dataType: 'json',
    type: 'POST'
  }).done( function(ret) {
    let media_id = "";
    if( ret.success == true ) {
      media_id = ret.data.id;
      $target.val(media_id);
      $this.addClass("hide");
      $remove.removeClass("hide");
      $title.removeClass("hide");
      reader.readAsDataURL(upfile);
      $loader.addClass("hide");
      let post_id = _$("#post_id").val();
      let user_id = _$("#user_id").val();
      if( post_id != undefined || user_id != undefined ) {
        _$.post( mypage_ajax.url, {
          _wp_nonce: mypage_ajax.image_nonce,
          action: ( post_id != undefined ? 'post_image_change' : 'user_profile_image_change' ),
          post_id: post_id,
          user_id: user_id,
          image_id: media_id,
          image_key: $this.data("target")
        }).done( function(ret){ console.log("画像アップロード&更新完了", ret); } ).fail( ajaxError );
      } else {
        console.log("画像新規アップロード完了", ret);
      }
    }
  }).fail( ajaxError );
});

// input[type=file] の削除処理
_$(".js-image-remover").on("click", function(e) {
  e.preventDefault();
  let $this = _$(this);
  let $preview = $this.prevAll(".preview");
  let $upload = $this.prev(".js-image-selector");
  let $target = _$("#" + $this.data("target"));
  let $title = _$("#" + $this.data("title"));
  let image_id = $target.val();
  let opt = {
    type : 'red',
    content : 'このファイルを本当に削除しますか？',
    buttons: {
      confirm: {
        text: '削除する',
        btnClass: 'btn-red',
        action: function() {
          $loader.removeClass("hide");
          return _$.post( mypage_ajax.url, {
            'action': 'delete_media_file',
            '_wp_nonce': mypage_ajax.image_delete_nonce,
            'image_id': image_id
          }).done( function(ret) {
            if( ret.success == true ) {
              $preview.html("");
              $target.val("");
              $upload.removeClass("hide").val("");
              $this.addClass("hide");
              $title.val("").addClass("hide");
              $loader.addClass("hide");
              let post_id = _$("#post_id").val();
              let user_id = _$("#user_id").val();
              let callback = function() {
                _$.dialog({
                  animation: 'opacity',
                  content: '画像を削除しました。'
                });
              };
              if( post_id != undefined || user_id != undefined ) {
                _$.post( mypage_ajax.url, {
                  _wp_nonce: mypage_ajax.image_nonce,
                  action: ( post_id != undefined ? 'post_image_change' : 'user_profile_image_change' ),
                  post_id: post_id,
                  user_id: user_id,
                  image_id: "",
                  image_key: $this.data("target")
                }).done( callback ).fail( ajaxError );
              } else {
                callback();
              }
            }
          }).fail( ajaxError );
        }
      },
      cancel: { text: 'キャンセル' }
    }
  };
  _$.confirm( opt );
  return false;
});

// ------------------------------------------------------------------------
// 投稿フォーム Submit処理(ajax通信）
_$("#postForm").on("submit", function(e) {
  e.preventDefault();
  let $form = _$(this);
  if( checkErrors(validations) == 0 ) {
    $loader.removeClass('hide');
    let post_data_json = getFormData($form);
    post_data_json.action = "edit_or_new_post";
    _$.post(mypage_ajax.url, post_data_json).done( function(ret) {
      let message = '';
      let res = JSON.parse( ret.data );
      if( ret.success ) {
        message = ( res.is_update ? '物件を編集しました。' : '新しい物件を追加しました。' );
      } else {
        console.log( res );
        message = 'エラーが発生しました。システム管理者までお問い合わせください。';
      }
      message += '<br><br><button class="js-close-result">閉じる</button>';
      $loader.addClass('hide');
      showResult(message);
      if( res.is_update ) {
        _$(".js-close-result").on("click", closeResult);
      } else {
        _$(".js-close-result").on("click", function(e) { e.preventDefault(); location.href = '/?section=items'; });
      }
      _$("html, body").animate({ scrollTop: 0 }, 400);
    }).fail( ajaxError );
  }
  return false;
});

_$(".js-status-change").on("click", function(e) {
  e.preventDefault();
  let $this = _$(this);
  let status = $this.data("status");
  let data = {
    "id": $this.data("id"),
    "status": status,
    "action": "post_status_change",
    "_wp_nonce": mypage_ajax.status_change_nonce
  };
  let opt = {
    buttons: {
      confirm: {
        action: function() {
          return _$.post( mypage_ajax.url, data ).done( function(ret) {
            let msg = (status == 'private' ? '非公開にしました。' : '物件を再掲載しました。');
            _$.alert({
              animation: 'opacity',
              title: 'ステータスの変更',
              content: msg,
              useBootstrap: false,
              draggable: false,
              close: function(){ location.reload(); }
            });
          }).fail( ajaxError );
        }
      },
      cancel: { text: 'キャンセル'}
    }
  };
  if( status == 'private' ) {
    opt.title = '物件の非公開設定';
    opt.type  = 'red';
    opt.content = 'この物件を非公開にします。よろしいですか？';
    opt.buttons.confirm.text = '非公開にする';
    opt.buttons.confirm.btnClass = 'btn-red';
  } else {
    opt.title = '物件の再掲載';
    opt.type  = 'blue';
    opt.content = 'この物件を再掲載します。よろしいですか？';
    opt.buttons.confirm.text = '再掲載する';
    opt.buttons.confirm.btnClass = 'btn-blue';
  }
  _$.confirm( opt );
});

// ------------------------------------------------------------------------
// Zoom会議フォーム Submit処理(ajax通信）
_$("#zoomForm").on("submit", function(e) {
  e.preventDefault();
  let $form = _$(this);
  if( checkErrors(validations) == 0 ) {
    $loader.removeClass('hide');
    let post_data_json = getFormData($form);
    post_data_json.action = "edit_or_new_stm_zoom";
    _$.post(mypage_ajax.url, post_data_json).done( function(ret) {
      let message = "";
      let res = JSON.parse( ret.data );
      if( ret.success ) {
        message = ( res.is_update ? '内見ツアーを編集しました。' : '内見ツアーを作成しました。' );
      } else {
        console.log( res );
        message = 'エラーが発生しました。システム管理者までお問い合わせください。';
      }
      message += '<br><br><button class="js-close-result">閉じる</button>';
      $loader.addClass('hide');
      showResult(message);
      if( res.is_update ) {
        _$(".js-close-result").on("click", closeResult);
      } else {
        _$(".js-close-result").on("click", function(e) { e.preventDefault(); location.href = '/?section=meetings'; });
      }
    }).fail( ajaxError );
  }
  return false;
});

_$(".js-meeting-cancel").on("click", function(e) {
  e.preventDefault();
  let $this = _$(this);
  let data = {
    "id": $this.data("id"),
    "action": "stm_cancel",
    "_wp_nonce": mypage_ajax.status_change_nonce
  };
  let opt = {
    title: '内見ツアーのキャンセル',
    type : 'red',
    content : 'このツアーを完全にキャンセルします。（復元はできません）<br>よろしいですか？',
    buttons: {
      confirm: {
        text: 'キャンセルする',
        btnClass: 'btn-red',
        action: function() {
          return _$.post( mypage_ajax.url, data ).done( function(ret) {
            console.log(ret);
            _$.dialog({
              animation: 'opacity',
              content: 'ツアーをキャンセルしました。',
              close: function(){ location.reload(); }
            });
          }).fail( ajaxError );
        }
      },
      cancel: { text: '何もしない'}
    }
  };
  _$.confirm( opt );
});

// ------------------------------------------------------------------------
// ユーザープロフィールフォーム Submit処理（常にupdate）
_$("#userProfileForm").on("submit", function(e) {
  e.preventDefault();
  let $form = _$(this);
  if( checkErrors(validations) == 0 ) {
    let post_data_json = getFormData($form);
    post_data_json.action = "user_profile_update";
    $loader.removeClass('hide');
    _$.post(mypage_ajax.url, post_data_json).done( function(ret) {
      let message = ret.success ? 'プロフィールを更新しました。' : ret.data;
      message += '<br><br><button class="js-close-result">閉じる</button>';
      $loader.addClass('hide');
      showResult(message);
      _$(".js-close-result").on("click", closeResult);
      _$("html, body").animate({ scrollTop: 0 }, 400);
    }).fail( ajaxError );
  }
  return false;
});

// ------------------------------------------------------------------------
// クリップボードのコピー処理
_$(".js-clipboard").on("click", function() {
  let url = _$(this).data("url");
  if (window.clipboardData) {
    window.clipboardData.setData("Text", url);
  } else {
    navigator.clipboard.writeText(url);
  }
  _$.dialog({
    animation: 'opacity',
    content: 'クリップボードにURLをコピーしました。'
  });
});
